
import Vue from 'vue';
import AtomLogo from '@/components/atoms/AtomLogo.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';

export default Vue.extend({
  name: 'TemplateMobileDevice',
  components: {
    AtomSvgIcon,
    AtomText,
    AtomLogo,
  },
  methods: {
    androidApp() {
      const url = 'https://play.google.com/store/apps/details?id=com.schwarz.andtwogo';
      window.location.replace(url);
    },
    iosApp() {
      window.location.replace('twogo://');

      setTimeout(() => {
        window.location.replace('https://apps.apple.com/app/id1486158117');
      }, 3000);
    },
  },
  computed: {
    getCurrentLocale() {
      return this.$t(`dictionary.locales.${this.$i18n.locale.replace('-', '_')}`);
    },
  },
});
