import { render, staticRenderFns } from "./TemplateMobileDevice.vue?vue&type=template&id=1efd67bb&scoped=true"
import script from "./TemplateMobileDevice.vue?vue&type=script&lang=ts"
export * from "./TemplateMobileDevice.vue?vue&type=script&lang=ts"
import style0 from "./TemplateMobileDevice.vue?vue&type=style&index=0&id=1efd67bb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1efd67bb",
  null
  
)

export default component.exports